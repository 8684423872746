import { iconProps } from "./"

export const MenuIcon = ({ className }: iconProps) => {
  return (
    <svg
      className={className}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 0C0.947 0 0.5 0.447 0.5 1V8H5.5V0H1.5ZM7.5 0V8H13.5V0H7.5ZM15.5 0V8H20.5V1C20.5 0.447 20.053 0 19.5 0H15.5ZM0.5 10V17C0.5 17.553 0.947 18 1.5 18H5.5V10H0.5ZM7.5 10V18H13.5V10H7.5ZM15.5 10V18H19.5C20.053 18 20.5 17.553 20.5 17V10H15.5Z" />
    </svg>
  )
}
