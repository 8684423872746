import Link from "next/link"
import { useRouter } from "next/router"

import { useGlobalContext } from "@context/global"
import { clsx } from "clsx"

export interface LocaleSwitcherProps {
  className?: string
}

export const LocaleSwitcher = ({ className }: LocaleSwitcherProps) => {
  const router = useRouter()
  const { locales } = useGlobalContext()

  // generate locale switcher buttons
  const _localeButtons = locales.map(({ locale, label }) => (
    <Link
      key={locale}
      href={router.asPath}
      locale={locale}
      className={clsx("btn-locale-switcher text-shadow", router.locale === locale && "active")}
    >
      {label}
    </Link>
  ))

  return <div className={clsx("z-50 space-x-6", className)}>{_localeButtons}</div>
}
