import { iconProps } from "./"

export const Department = ({ className }: iconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 0.333374L0.5 5.00004V22.5H21.5V5.00004L11 0.333374ZM7.5 19H5.16667V16.6667H7.5V19ZM7.5 14.3334H5.16667V12H7.5V14.3334ZM7.5 9.66671H5.16667V7.33337H7.5V9.66671ZM12.1667 19H9.83333V16.6667H12.1667V19ZM12.1667 14.3334H9.83333V12H12.1667V14.3334ZM12.1667 9.66671H9.83333V7.33337H12.1667V9.66671ZM16.8333 19H14.5V16.6667H16.8333V19ZM16.8333 14.3334H14.5V12H16.8333V14.3334ZM16.8333 9.66671H14.5V7.33337H16.8333V9.66671Z" />
    </svg>
  )
}
