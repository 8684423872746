import { create } from "zustand"

import { PageTransitionDirection } from "@models/common"

export interface GlobalStoreProps {
  transitionDuration: number
  menuTransitionDirection: PageTransitionDirection
  pageTransitionDirection: PageTransitionDirection
  toggleMenu: (direction: PageTransitionDirection) => void
  setToggleMenu: (func: (direction: PageTransitionDirection) => void) => void
  setPageTransitionDirection: (direction: PageTransitionDirection) => void
}

export const useGlobalStore = create<GlobalStoreProps>(set => ({
  transitionDuration: 0.5,
  menuTransitionDirection: "up",
  pageTransitionDirection: "down",
  toggleMenu: () => console.log("toggle menu..."),
  setPageTransitionDirection: direction =>
    set(state => ({ ...state, pageTransitionDirection: direction })),
  setToggleMenu: func => set(state => ({ toggleMenu: func })),
}))
