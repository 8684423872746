import { ReactNode } from "react"

import { clsx } from "clsx"

import { PageTransitionDirection } from "@models/common"

import { AppLink } from "./AppLink"

export interface PageTransitionLinkProps {
  href: string
  children: ReactNode
  className?: string
  pageTransitionDirection?: PageTransitionDirection
  hasSideEffect?: boolean
  sideEffect?: () => void
}

export const PageTransitionLink = ({
  href,
  children,
  className,
  pageTransitionDirection,
  hasSideEffect,
  sideEffect,
}: PageTransitionLinkProps) => {
  const link = hasSideEffect ? (
    <div className={clsx("cursor-pointer", className)} onClick={sideEffect}>
      {children}
    </div>
  ) : (
    <AppLink href={href} pageTransitionDirection={pageTransitionDirection} className={className}>
      {children}
    </AppLink>
  )

  return link
}
