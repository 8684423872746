import { ReactNode, useCallback, useEffect, useRef, useState } from "react"

import { pageTransitionVariants, springTransition } from "@/lib/animation/variants"
import { GlobalContext } from "@context/global"
import { clsx } from "clsx"
import { AnimatePresence, motion } from "framer-motion"

import { Header, Menu } from "@components/menu"
import { Metadata, MetadataProps } from "@components/shared/Metadata"
import {
  ACFGlobalFields,
  HeaderBackgroundType,
  PageTransitionDirection,
  ScrollDirection,
} from "@models/common"
import { useGlobalStore } from "@store/globalStore"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export interface LayoutProps {
  children: ReactNode
  acfGlobalFields: ACFGlobalFields
  seo: MetadataProps
  isHomePage?: boolean
  headerBackgroundType?: HeaderBackgroundType
  isFadeHeader?: boolean
  className?: string
}

export const Layout = ({
  children,
  acfGlobalFields,
  seo,
  isHomePage,
  headerBackgroundType,
  isFadeHeader,
  className,
}: LayoutProps) => {
  useEffect(() => {
    // Fire "new page" to trigger Page View
    if (window.dataLayer?.length > 0) {
      window.dataLayer.push({ event: "react-new-page" })
    }
  }, [])

  const { cookieBanner } = acfGlobalFields.acfGlobal
  const { pageTransitionDirection, setToggleMenu } = useGlobalStore(state => ({
    pageTransitionDirection: state.pageTransitionDirection,
    setToggleMenu: state.setToggleMenu,
  }))

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuDirection, setMenuDirection] = useState<PageTransitionDirection>("up")

  const toggleMenu = useCallback((direction: PageTransitionDirection) => {
    setIsMenuOpen(open => !open)
    setMenuDirection(direction)
  }, [])

  useEffect(() => {
    setToggleMenu(toggleMenu)
  }, [setToggleMenu, toggleMenu])

  const lastScrollY = useRef<number>(0)
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollY = e.currentTarget.scrollTop
    const direction: ScrollDirection = scrollY > lastScrollY.current ? "down" : "up"

    if (
      direction !== scrollDirection &&
      (scrollY - lastScrollY.current > 12 || scrollY - lastScrollY.current < -13)
    ) {
      setScrollDirection(direction)
    }

    if (scrollY < 80) {
      setScrollDirection(null)
    } else {
      if (scrollDirection === null) {
        setScrollDirection("down")
      } else if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY.current > 12 || scrollY - lastScrollY.current < -13)
      ) {
        setScrollDirection(direction)
      }
    }

    lastScrollY.current = scrollY > 0 ? scrollY : 0
  }

  return (
    <GlobalContext.Provider value={acfGlobalFields.acfGlobal}>
      <Metadata {...seo} />
      {/* This motion div wrapper is for Page transition */}
      <motion.div
        variants={pageTransitionVariants}
        initial="initial"
        animate="enter"
        exit="exit"
        custom={pageTransitionDirection}
        className="animated-page fixed inset-0 bg-white"
      >
        <AnimatePresence mode="wait">
          {isMenuOpen && <Menu toggleMenu={toggleMenu} menuTransitionDirection={menuDirection} />}
        </AnimatePresence>
        {/* Slides down if menu is "open" */}
        <motion.div
          key="mainPageContent"
          animate={{ y: isMenuOpen ? (menuDirection === "up" ? "-100vh" : "100vh") : 0 }}
          transition={springTransition}
          className="flex h-full flex-col"
        >
          <main className="relative flex flex-1 flex-col overflow-hidden">
            {!isHomePage && (
              <Header
                backgroundType={headerBackgroundType}
                scrollDirection={scrollDirection}
                className={clsx(
                  "transition-all duration-700 ease-in-out",
                  scrollDirection === "down" && "lg:-translate-y-full",
                  (scrollDirection === "up" || scrollDirection === null) && "lg:translate-y-0",
                  isFadeHeader && "translate-y-4 opacity-0"
                  // {
                  //   "bg-black-olive bg-opacity-20 backdrop-blur lg:-translate-y-full":
                  //     scrollDirection === "down",
                  // },
                  // {
                  //   "bg-black-olive bg-opacity-20 backdrop-blur lg:translate-y-0":
                  //     scrollDirection === "up",
                  // },
                  // { "backdrop-blur-0 lg:translate-y-0": scrollDirection === null },
                  // { "translate-y-4 opacity-0": isFadeHeader }
                )}
              />
            )}
            <div
              className={clsx("relative w-screen flex-1", className, {
                "overflow-y-auto overflow-x-hidden": !isHomePage,
              })}
              onScroll={scrollHandler}
            >
              {children}
            </div>

            {/* Cookie Banner */}
            {/* <CookieBanner {...cookieBanner} /> */}
          </main>
        </motion.div>
      </motion.div>
    </GlobalContext.Provider>
  )
}
