import Link from "next/link"
import { useRouter } from "next/router"

import { springTransition } from "@animation/variants"
import { useGlobalContext } from "@context/global"
import { useResponsiveMD } from "@hooks/shared"
import { clsx } from "clsx"
import { motion } from "framer-motion"

import { LocaleSwitcher } from "@components/locale-switcher"
import Image from "@components/shared/AppImage"
import { PageTransitionLink } from "@components/shared/PageTransitionLink"
import { Close } from "@icons/Close"
import { Department } from "@icons/Department"
import { PageTransitionDirection } from "@models/common"

export interface MenuProps {
  toggleMenu: (direction: PageTransitionDirection) => void
  menuTransitionDirection: PageTransitionDirection
}

export const Menu = ({ toggleMenu, menuTransitionDirection }: MenuProps) => {
  const { pathname } = useRouter()
  const isResponsiveMD = useResponsiveMD()
  const {
    menu: { pages },
    bokaVisning,
  } = useGlobalContext()

  return (
    <motion.div
      key="menu"
      initial={{ y: menuTransitionDirection === "up" ? "100vh" : "-100vh" }}
      animate={{ y: 0 }}
      exit={{ y: menuTransitionDirection === "up" ? "100vh" : "-100vh" }}
      transition={springTransition}
      className="fixed inset-0 h-full w-full"
    >
      <PageTransitionLink
        href="/"
        pageTransitionDirection="down"
        className="absolute left-3 top-[6px] z-50 cursor-pointer p-2 lg:left-6 lg:top-3"
        hasSideEffect={pathname === "/"}
        sideEffect={toggleMenu.bind(null, menuTransitionDirection)}
      >
        <Department />
      </PageTransitionLink>

      <div
        onClick={toggleMenu.bind(null, menuTransitionDirection)}
        className={clsx(
          "absolute left-3 right-3 top-2 z-50 cursor-pointer p-2 lg:right-6 lg:left-6 lg:top-4",
          isResponsiveMD ? "flex items-center space-x-8" : "grid grid-cols-3"
        )}
      >
        <LocaleSwitcher />
        <Link
          href={bokaVisning.link}
          className="text-white transition hover:text-white/80 text-center"
        >
          <span className="lg:ml-6 text-shadow">{bokaVisning.label}</span>
        </Link>
        {isResponsiveMD && <div className="flex-1" />}
        <Close className="app-hover fill-white hover:fill-platinum place-self-end drop-shadow" />
      </div>

      {/* Menu Grid */}
      <div className="grid h-full w-full grid-cols-2 grid-rows-4 text-white lg:grid-cols-[42.82%_1fr_1fr] lg:grid-rows-6">
        {pages &&
          pages.map(({ label, page, overlayColor, pattern }, index) => {
            // pages in backend are localized using polylang
            // swedish pages have the default slugs while english pages have the same slug with '-english' suffix
            // wp slug is irrelevant here since the locale handling is handled automagically by Next
            const nextSlug = page.slug.replace(/-english/g, "")

            return (
              <PageTransitionLink
                key={page.slug}
                href={nextSlug}
                pageTransitionDirection="up"
                className={`grid-block grid-block-${index + 1}`}
                hasSideEffect={pathname.endsWith(page.slug)}
                sideEffect={toggleMenu.bind(null, menuTransitionDirection)}
              >
                <div
                  className="group relative h-full w-full"
                  style={{ backgroundColor: overlayColor }}
                >
                  {pattern && (
                    <div className="pattern absolute h-full w-full">
                      <Image
                        className="object-cover"
                        src={pattern.mediaItemUrl}
                        alt={pattern.altText}
                        fill
                      />
                    </div>
                  )}

                  <div className="absolute inset-0 z-20 transition-opacity duration-500 ease-in-out group-hover:opacity-0">
                    <Image
                      className="object-cover"
                      src={page.featuredImage.node.mediaItemUrl}
                      alt={page.featuredImage.node.altText}
                      fill
                    />

                    <div className="absolute inset-0 z-10 h-full w-full bg-black bg-opacity-40"></div>
                  </div>
                  <h3
                    className={clsx(
                      "app-h3 hyphens-auto absolute bottom-4 z-30 w-full px-5 uppercase lg:bottom-7 lg:px-8 group-hover:text-[#1c1c1e]",
                      (label || page.title) === "Om Jakobsbergsgatan 24" && "break-words pr-6"
                    )}
                  >
                    {label || page.title}
                  </h3>
                </div>
              </PageTransitionLink>
            )
          })}
      </div>
    </motion.div>
  )
}
