import { iconProps } from "./"

export const Close = ({ className }: iconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.49154 0.841797L0.841797 2.49154L9.35026 11L0.841797 19.5085L2.49154 21.1582L11 12.6497L19.5085 21.1582L21.1582 19.5085L12.6497 11L21.1582 2.49154L19.5085 0.841797L11 9.35026L2.49154 0.841797Z" />
    </svg>
  )
}
