import NextImage, { ImageProps as NextImageProps } from "next/image"
import { useState } from "react"

import { clsx } from "clsx"

interface ImageProps extends Omit<NextImageProps, "src" | "alt"> {
  src?: NextImageProps["src"] | null
  alt?: string | null
}

export default function Image(props: ImageProps) {
  const [loaded, setLoaded] = useState(false)

  // no src is provided, render nothing
  if (!props.src) return null

  // generate class names based on whether the image is loaded or not
  const handleLoadingComplete = () => setLoaded(true)
  const classes = clsx(
    props.className,
    props.className?.includes("transition") ? null : "transition duration-300",
    loaded ? "" : "opacity-0 blur-xl"
  )

  // no alt is provided, render a presentation role
  if (!props.alt) {
    return (
      <NextImage
        {...props}
        className={classes}
        src={props.src}
        alt=""
        role="presentation"
        onLoadingComplete={handleLoadingComplete}
      />
    )
  }

  return (
    <NextImage
      {...(props as NextImageProps)}
      className={classes}
      onLoadingComplete={handleLoadingComplete}
    />
  )
}
