import Link from "next/link"
import Router, { useRouter } from "next/router"
import { ReactNode, SyntheticEvent, useCallback } from "react"

import { clsx } from "clsx"

import { PageTransitionDirection } from "@models/common"
import { useGlobalStore } from "@store/globalStore"

export interface AppLinkProps {
  href?: string
  children?: ReactNode
  className?: string
  pageTransitionDirection?: PageTransitionDirection
}

export const AppLink = ({ href, children, className, pageTransitionDirection }: AppLinkProps) => {
  const router = useRouter()
  const currentLocale = router.locale

  const { setPageTransitionDirection } = useGlobalStore(state => ({
    setPageTransitionDirection: state.setPageTransitionDirection,
  }))
  const needsNextLink = typeof href === "string" && href.startsWith("/") && !pageTransitionDirection

  let fullUrl: string = ""
  if (typeof href === "string" && pageTransitionDirection) {
    fullUrl =
      process.env.NEXT_PUBLIC_BASE_URL! + (currentLocale === "en" ? currentLocale + "/" : "") + href
  }

  const onCLick = useCallback(
    (e: SyntheticEvent) => {
      // scroll to a section in the current page
      if (typeof href === "string" && href.startsWith("#")) {
        e.preventDefault()

        // scroll to target section
        try {
          const targetSection = document.querySelector(href)
          if (!targetSection) return
          targetSection.scrollIntoView({ behavior: "smooth" })
        } catch (_) {}
      }

      // open external link in a new tab
      else if (
        typeof href === "string" &&
        (href.startsWith("http://") || href.startsWith("https://")) &&
        new URL(href).host !== window.location.host
      ) {
        e.preventDefault()
        window.open(href, "_blank", "noopener,noreferrer")
      } else if (typeof href === "string" && pageTransitionDirection) {
        e.preventDefault()
        setPageTransitionDirection(pageTransitionDirection)
        Router.push(href)
      }
    },
    [href, setPageTransitionDirection, pageTransitionDirection]
  )

  const container = (
    <a
      className={clsx("cursor-pointer outline-none", className)}
      href={needsNextLink ? href : fullUrl}
      onClick={needsNextLink ? undefined : onCLick}
    >
      {children}
    </a>
  )

  return needsNextLink ? (
    <Link href={href} legacyBehavior>
      {container}
    </Link>
  ) : (
    container
  )
}
