import { Variants } from "framer-motion"

import { PageTransitionDirection } from "@models/common"

export const springTransition = { type: "spring", stiffness: 50, bounceDamping: 1, duration: 0.5 }

export const pageTransitionVariants: Variants = {
  initial: (pageTransitionDirection: PageTransitionDirection) => ({
    y:
      pageTransitionDirection === "down"
        ? "-100vh"
        : pageTransitionDirection === "up"
        ? "100vh"
        : 0,
  }),
  enter: () => ({
    y: 0,
    transition: springTransition,
  }),
  exit: (pageTransitionDirection: PageTransitionDirection) => ({
    y:
      pageTransitionDirection === "down"
        ? "100vh"
        : pageTransitionDirection === "up"
        ? "-100vh"
        : 0,
    transition: springTransition,
  }),
}

export const PlayerVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.2, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 1, ease: "easeOut" } },
}
