import { useRouter } from "next/router"

import { useGlobalContext } from "@context/global"
import { clsx } from "clsx"

import { AppLink } from "@components/shared/AppLink"
import { Department } from "@icons/Department"
import { MenuIcon } from "@icons/MenuIcon"
import { HeaderBackgroundType } from "@models/common"
import { useGlobalStore } from "@store/globalStore"

export interface HeaderProps {
  backgroundType?: HeaderBackgroundType
  className?: string
  scrollDirection?: "up" | "down" | null
}

export const Header = ({ backgroundType, className, scrollDirection }: HeaderProps) => {
  const router = useRouter()
  const toggleMenu = useGlobalStore(state => state.toggleMenu)
  const {
    menu: { pages },
  } = useGlobalContext()
  const shouldUseBlackText = scrollDirection === null && backgroundType === "color"
  const bgClasses =
    scrollDirection === "up" || scrollDirection === "down" || backgroundType === "blur"
      ? "bg-black-olive bg-opacity-20 backdrop-blur"
      : backgroundType === "color"
      ? "bg-[#fafafa]"
      : ""

  return (
    <header
      className={clsx(
        "absolute top-0 z-50 flex w-full items-center justify-between py-3 px-4 lg:px-6 lg:pt-[22px] lg:pb-[23px]",
        bgClasses,
        className
      )}
    >
      <AppLink href="/" pageTransitionDirection="up">
        <Department
          className={clsx(
            "app-hover",
            shouldUseBlackText
              ? "fill-[#1c1c1e] hover:fill-[#808080]"
              : "fill-white hover:fill-platinum"
          )}
        />
      </AppLink>
      <nav
        className={clsx(
          "text-body flex lg:space-x-12 font-normal",
          shouldUseBlackText ? "text-[#1c1c1e]" : "text-white"
        )}
      >
        {pages &&
          pages.map(({ label, page }) => {
            // pages in backend are localized using polylang
            // swedish pages have the default slugs while english pages have the same slug with '-english' suffix
            // wp slug is irrelevant here since the locale handling is handled automagically by Next
            const nextSlug = page.slug.replace(/-english/g, "")
            const isPageActive = router.pathname === "/" + nextSlug

            // ensure link hrefs end with a slash
            const linkHref = nextSlug.endsWith("/") ? nextSlug : nextSlug + "/"

            return isPageActive ? (
              <span
                key={page.slug}
                className={clsx(
                  shouldUseBlackText ? "lg:nav-item-light" : "lg:nav-item",
                  "relative cursor-default lg:after:opacity-100"
                )}
              >
                {label || page.title}
              </span>
            ) : (
              <AppLink
                key={page.slug}
                href={linkHref}
                pageTransitionDirection="none"
                className={clsx(
                  "relative hidden lg:inline",
                  shouldUseBlackText ? "lg:nav-item-light" : "lg:nav-item"
                )}
              >
                {label || page.title}
              </AppLink>
            )
          })}
      </nav>
      <button onClick={toggleMenu.bind(null, "down")}>
        <MenuIcon
          className={clsx(
            "app-hover",
            shouldUseBlackText
              ? "fill-[#1c1c1e] hover:fill-[#808080]"
              : "fill-white hover:fill-platinum"
          )}
        />
      </button>
    </header>
  )
}
