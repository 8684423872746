import Head from "next/head"
import { useRouter } from "next/router"

import { getAppLocale } from "@utils/locale"
import { getSiteUrl } from "@utils/urls"

export interface MetadataProps {
  title: string
  metaTitle: string
  metaDescription: string
  twitterTitle: string
  twitterDescription: string
  opengraphTitle: string
  opengraphDescription: string
  image?: string
  url?: string
  slug?: string
  locales?: { locale: string }[]
}

export const Metadata = ({
  title,
  metaTitle,
  metaDescription,
  twitterTitle,
  twitterDescription,
  opengraphTitle,
  opengraphDescription,
  image,
  url,
  slug,
  locales,
}: MetadataProps) => {
  const _hrefLangs = locales?.map(({ locale }) => (
    <link
      key={locale}
      rel="alternate"
      hrefLang={getAppLocale(locale)}
      href={getAlternateHref(locale, slug)}
    />
  ))

  const router = useRouter()
  const currentLocale = router.locale
  const _url = getAlternateHref(currentLocale as string, slug)

  return (
    <Head>
      {/* SEO */}
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={opengraphTitle} />
      <meta property="og:description" content={opengraphDescription} />
      {image && <meta property="og:image" content={image} />}
      {_url && <meta property="og:url" content={_url} />}
      {_url && <link rel="canonical" href={_url} />}
      {_hrefLangs}
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content={twitterTitle ? twitterTitle : metaTitle} />
      <meta
        property="twitter:description"
        content={twitterDescription ? twitterDescription : metaDescription}
      />
      {image && <meta property="twitter:image" content={image} />}

      <meta name="google-site-verification" content="S5oo6R96omxl_N7MiAmfl-m1A0T_Jdk-LyqKHQeF97o" />

      {/* Prevent search engines from indexing this page */}
      {process.env.NEXT_PUBLIC_NOINDEX === "noindex" && <meta name="robots" content="noindex" />}

      {/* Favicon Stuff */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  )
}

function getAlternateHref(locale: string, slug: string | undefined) {
  let href = ""
  if (slug && slug !== "/") {
    href =
      process.env.NEXT_PUBLIC_BASE_URL +
      (locale !== "sv" ? `en/${slug.replace(/\/?$/, "/")}` : slug.replace(/\/?$/, "/"))
  } else {
    href = process.env.NEXT_PUBLIC_BASE_URL + (locale !== "sv" ? "en/" : "")
  }

  return href
}
